<template>
  <div class="bar-chart" ref="bar_echart" />
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
          // this.setOption(val);
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.bar_echart);
      this.setOptions(this.chartData);
    },
    /**
     * setOptions需要接参数的
     */
    setOptions(val) {
      const dateList = val.map(function (item) {
        return item[1];
      });
      const valueList = val.map(function (item) {
        return item[0];
      });
      if (val.length == 0) {
        this.chart.setOption({
          title: [
            {
              left: '15',
              top: '10',
              text: '单患者合并症数量分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
          grid: {
            left: '6%',
            right: '6%',
            bottom: '3%',
            top: '18%',
            containLabel: true,
          },
        });
      } else if (val.length !== 0) {
        this.chart.setOption({
          xAxis: [
            {
              type: 'category',
              data: dateList,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                inside: false,
                margin: 15,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              min: 0,
              axisLabel: {
                inside: false,
                margin: 15,
              },
              axisTick: {
                show: false,
              },
            },
          ],
          title: [
            {
              left: '15',
              top: '10',
              text: '单患者合并症数量分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
          grid: {
            left: '6%',
            right: '6%',
            bottom: '3%',
            top: '18%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              const content = `<div class="tooltip-txt" style="color:#2c93fa;">${params[0].value}</div>`;
              return content;
            },
          },
          series: [
            {
              type: 'bar',
              data: valueList,
              barMinWidth: '5%',
              barMaxWidth: '10%',

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#2B94F9' },
                  { offset: 0.5, color: '#2B94F9' },
                  { offset: 1, color: '#2A94F9' },
                ]),
              },
              markPoint: {
                data: [{ type: 'max', name: 'Max' }],
              },
            },
          ],
        });
      }

      var _that = this;
      window.addEventListener('resize', () => {
        _that.chart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bar-chart {
  width: 100%;
  height: 100%;
}
.tooltip-txt {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 20px;
}
</style>
