<!-- 基础饼图 -->
<template>
  <div class="gauge-chart" ref="gauge"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        // this.setOption(val);
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.gauge);
      this.setOptions(this.chartData);
    },
    /**
     * setOptions需要接参数的
     */
    setOptions(val) {
      if (val.length == 0) {
        var option = {
          title: [
            {
              left: '5',
              top: '10',
              text: '性别分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
        };
        this.chart.setOption(option);
      } else if (val.length !== 0) {
        var seriesArr = [];
        var colors = [
          ['#57bbf7', 'pink'],
          ['#ffc969', '#f4f4f7'],
          ['#f38b97', '#f4f4f7'],
        ];
        val.forEach(function (item, index) {
          seriesArr.push({
            name: item.name,
            type: 'pie',
            color: '#2D94FC',
            startAngle: item.name === '男性占比' ? 90 : 0,
            clockWise: true,
            radius: [60, 75],
            itemStyle: {
              normal: {
                shadowColor: colors[index][0],
                shadowBlur: 0,
                label: {
                  show: true,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            center: [
              index === 0 ? index * 15 + 25 + '%' : index * 70 + '%',
              index < 2 ? '40%' : '75%',
            ],
            data: [
              {
                value: item.value,
                label: {
                  normal: {
                    formatter: function (params) {
                      return [
                        '{a|' + params.value + '}' + '{c|%}',
                        '{b|' + params.seriesName + '}',
                      ].join('\n');
                    },
                    rich: {
                      a: {
                        color: '#333333',
                        fontWeight: 500,
                        fontSize: 48,
                      },
                      b: {
                        color: '#323233',
                        fontSize: 12,
                      },
                      c: {
                        width: 14,
                        height: 20,
                        color: '#333333',
                      },
                    },
                    position: 'center',
                    show: true,
                    textStyle: {
                      fontSize: '20',
                      fontWeight: 'bold',
                      color: colors[index][0], //跟随其他颜色
                    },
                  },
                },
              },
              {
                value: 100 - item.value,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      { offset: 0, color: '#4EC966' },
                      { offset: 0.5, color: '#87E3A4' },
                      { offset: 1, color: '#F6F8FC' },
                    ]),
                  },
                },
              },
            ],
          });
        });
        var options = {
          title: [
            {
              left: '5',
              top: '10',
              text: '性别分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
          legend: {
            right: 'center',
            bottom: '18%',
            show: true,
            itemWidth: 12,
            color: '#4EC966',
            itemHeight: 10,
            minWidth: '100%',
            backgroundColor: '#F9F9FB',
            padding: [10, 30, 10, 30],
            selectedMode: false,
            textStyle: {
              color: '#000000',
              fontWeight: 400,
              lineHeight: 17,
              height: 17,
            },
            data: val,
          },
          series: seriesArr,
        };
        this.chart.setOption(options);
      }

      // this.chart.setOption({

      //   // legend: {
      //   //   bottom: "bottom",
      //   //   width: "94%",
      //   //   itemWidth: 12,
      //   //   itemHeight: 10,
      //   //   backgroundColor: "#F9F9FB",
      //   //   padding: [10, 30, 10, 30],
      //   //   textStyle: {
      //   //     color: "#000000",
      //   //     fontWeight: 400,
      //   //     lineHeight: 17,
      //   //     height: 17,
      //   //   },
      //   // },
      //   series: [
      //     {
      //       type: "gauge",
      //       startAngle: 90,
      //       radius: "69%",
      //       endAngle: -270,
      //       pointer: {
      //         show: false,
      //       },

      //       legend: {
      //         show: true,
      //       },
      //       progress: {
      //         show: true,
      //         //   overlap: false,
      //         //   roundCap: true,
      //         width: 25,

      //         itemStyle: {
      //           borderWidth: 2,
      //           borderColor: "#F4FFFF",
      //           color: "#2997F6",
      //         },
      //       },
      //       axisTick: {
      //         show: false,
      //       },
      //       axisLabel: {
      //         show: false,
      //       },
      //       splitLine: {
      //         show: false,
      //         distance: 0,
      //         length: 10,
      //       },
      //       axisLine: {
      //         lineStyle: {
      //           width: 18,
      //           color: [[1, "#2ED86C"]],
      //         },
      //       },

      //       data: val ? val : valueList,
      //       title: {
      //         fontWeight: 400,
      //         color: "#333333",
      //         lineHeight: 17,
      //         offsetCenter: ["0", "55%"],
      //         fontFamily: "PingFangSC-Regular, PingFang SC",
      //         height: 17,
      //         fontSize: 14,
      //       },
      //       detail: {
      //         width: 50,
      //         height: 14,
      //         fontSize: 14,
      //         offsetCenter: ["0", "10%"],
      //         //   color: "auto",
      //         //   borderColor: "auto",
      //         //   borderRadius: 20,
      //         //   borderWidth: 1,
      //         formatter: function (value) {
      //           return "{value|" + value.toFixed(0) + "}{unit|%}";
      //         },
      //         rich: {
      //           value: {
      //             fontSize: 48,
      //             height: 67,
      //             lineHeight: 67,
      //             fontWeight: 500,
      //             fontFamily: "PingFangSC-Medium, PingFang SC",
      //             color: "#333333",
      //           },
      //           unit: {
      //             height: 20,
      //             fontSize: 14,
      //             color: "#323233",
      //             fontWeight: 500,
      //             lineHeight: 20,
      //             fontFamily: "PingFangSC-Medium, PingFang SC",
      //             padding: [0, 0, -20, 5],
      //           },
      //         },
      //       },
      //     },
      //   ],
      // });
      var _that = this;
      window.addEventListener('resize', () => {
        _that.chart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.gauge-chart {
  width: 100%;
  height: 100%;
}
</style>
