<!-- 基础饼图 -->
<template>
  <div class="pie-chart" ref="pie"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.pie);
      this.setOptions(this.chartData);
    },
    /**
     * setOptions需要接参数的
     */
    setOptions(val) {
      var _that = this;
      if (val.length == 0) {
        this.chart.setOption({
          title: [
            {
              left: '15',
              top: '10',
              text: '新入会员年龄分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
        });
      } else if (val.length !== 0) {
        this.chart.setOption({
          title: [
            {
              left: '15',
              top: '10',
              text: '新入会员年龄分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
          legend: {
            bottom: '15%',
            width: '80%',
            itemWidth: 12,
            itemHeight: 10,
            backgroundColor: '#F9F9FB',
            padding: [10, 30, 10, 30],
            textStyle: {
              color: '#000000',
              fontWeight: 400,
              lineHeight: 17,
              height: 17,
            },
          },
          series: [
            {
              type: 'pie',
              radius: [50, 80],
              width: '100%',

              label: {
                width: 200,
                color: '#333333',
                formatter: '{b},{c}%',
                fontFamily: 'PingFangSC-Regular PingFang SC',
                fontWeight: 400,
                lineHeight: 17,
              },
              center: ['50%', '40%'],
              labelLine: {
                show: true,
                length: 20,
                length2: 10,
              },
              emphasis: {
                scaleSize: 6,
              },
              data: val,
            },
          ],
        });
      }

      window.addEventListener('resize', () => {
        _that.chart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>
