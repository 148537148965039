<!--  -->
<template>
  <div class="line-chart" ref="line"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  components: {},
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    echartName: {
      type: String,
      default: 'chart',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {},
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.line);
      this.setOptions(this.chartData);
    },
    setOptions(val) {
      //这里用var _that = this; 是因为在这里this存在指向性问题
      var _that = this;

      if (val.length == 0) {
        if (this.echartName === '血压') {
          this.chart.setOption({
            title: [
              {
                left: '15',
                top: '8',
                text: '会员血压达标率',
                textStyle: {
                  color: '#323233',
                  lineHeight: 20,
                  fontSize: 16,
                  fontWeight: '600',
                  fontFamily: ' PingFangSC-Medium, PingFang SC',
                },
              },
            ],
            grid: {
              left: '18%',
              right: '17%',
              bottom: '7%',
              top: '20%',
              containLabel: true,
            },
          });
        } else if (this.echartName === '心率') {
          this.chart.setOption({
            title: [
              {
                left: '15',
                top: '8',
                text: '会员心率达标率',
                textStyle: {
                  color: '#323233',
                  lineHeight: 20,
                  fontSize: 16,
                  fontWeight: '600',
                  fontFamily: ' PingFangSC-Medium, PingFang SC',
                },
              },
            ],
            grid: {
              left: '18%',
              right: '17%',
              bottom: '7%',
              top: '20%',
              containLabel: true,
            },
          });
        }
      }
      if (val.length !== 0) {
        if (this.echartName === '血压') {
          const dateList = val.map(function (item) {
            return item[0];
          });
          this.chart.setOption({
            xAxis: {
              data: dateList,
              boundaryGap: false,
              axisLabel: {
                inside: false,
                margin: 15,
              },
            },
            title: [
              {
                left: '15',
                top: '8',
                text: '会员血压达标率',
                textStyle: {
                  color: '#323233',
                  lineHeight: 20,
                  fontSize: 16,
                  fontWeight: '600',
                  fontFamily: ' PingFangSC-Medium, PingFang SC',
                },
              },
            ],
            grid: {
              left: '18%',
              right: '17%',
              bottom: '7%',
              top: '20%',
              containLabel: true,
            },
            tooltip: {
              trigger: 'axis',
              formatter: function (params) {
                const content =
                  '<div class="tooltip">达标率</div>' +
                  `<div class="tooltip-txt" style="color:#2c93fa;">${params[0].value[1]}%</div>`;
                return content;
              },
            },
            yAxis: {
              scale: true,
              type: 'value',
              min: 0,
              splitNumber: 5,
              axisLabel: {
                inside: false,
                margin: 15,
                formatter: '{value}%',
              },
              nameTextStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontFamily: 'HelveticaNeue',
                lineHeight: 20,
              },
            },
            series: [
              {
                // name: "expected",
                itemStyle: {
                  normal: {
                    color: '#2C93FA ',
                    borderColor: '#E7FEFF', //  拐点边框颜色
                    borderWidth: 1, //  拐点边框宽度
                    shadowColor: '#B9E7FF', //  阴影颜色
                    shadowBlur: 1, //  阴影渐变范围控制
                    lineStyle: {
                      color: '#2C93FA ',
                      width: 2,
                    },
                  },
                },
                symbol: 'circle',
                symbolSize: 5,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#2C93FA',
                    },
                    {
                      offset: 1,
                      color: '#FFFFFF',
                    },
                  ]),
                },
                type: 'line',
                data: val,
              },
            ],
          });
        } else if (this.echartName === '心率') {
          const dateList = val.map(function (item) {
            return item[0];
          });
          this.chart.setOption({
            xAxis: {
              data: dateList,
              boundaryGap: false,
              axisLabel: {
                inside: false,
                margin: 15,
              },
            },
            title: [
              {
                left: '15',
                top: '8',
                text: '会员心率达标率',
                textStyle: {
                  color: '#323233',
                  lineHeight: 20,
                  fontSize: 16,
                  fontWeight: '600',
                  fontFamily: ' PingFangSC-Medium, PingFang SC',
                },
              },
            ],
            grid: {
              left: '18%',
              right: '17%',
              bottom: '7%',
              top: '20%',
              containLabel: true,
            },
            tooltip: {
              trigger: 'axis',
              formatter: function (params) {
                const content =
                  '<div class="tooltip">达标率</div>' +
                  `<div class="tooltip-txt" style="color:#2c93fa;">${params[0].value[1]}%</div>`;
                return content;
              },
            },
            yAxis: {
              scale: true,
              type: 'value',
              min: 0,
              splitNumber: 5,
              axisLabel: {
                inside: false,
                margin: 15,
                formatter: '{value}%',
              },
              nameTextStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontFamily: 'HelveticaNeue',
                lineHeight: 20,
              },
            },
            series: [
              {
                // name: "expected",
                itemStyle: {
                  normal: {
                    color: '#35CB5B ',
                    borderColor: '#E7FEFF', //  拐点边框颜色
                    borderWidth: 1, //  拐点边框宽度
                    shadowColor: '#B9E7FF', //  阴影颜色
                    shadowBlur: 1, //  阴影渐变范围控制
                    lineStyle: {
                      color: '#60B876',
                      width: 2,
                    },
                  },
                },
                symbol: 'circle',
                symbolSize: 5,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#60B876',
                    },
                    {
                      offset: 1,
                      color: '#FFFFFF',
                    },
                  ]),
                },
                type: 'line',
                data: val,
              },
            ],
          });
        }
      }

      window.addEventListener('resize', () => {
        _that.chart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.line-chart {
  width: 100%;
  height: 100%;
}
.tooltip {
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin: 12px 26px 4px;
  color: #666666;
  line-height: 17px;
}
.tooltip-txt {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 20px;
}
</style>
