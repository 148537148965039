<template>
  <!-- class="page-wrapper" -->
  <div>
    <div class="seach-box">
      <el-form
        ref="searchFrom"
        size="mini"
        :inline="true"
        :model="searchFrom"
        class="demo-form-inline"
      >
        <el-form-item label="患者信息：" class="minclass2">
          <el-input
            v-model="searchFrom.userContent"
            placeholder="请输入关键词信息"
            suffix-icon="el-icon-search"
          ></el-input>
        </el-form-item>
        <!-- 缺少条件判断，医生类型为主任时可下拉，不然不能下拉 -->
        <el-form-item label="工作室名称：">
          <el-select
            @change="choseGroupId"
            v-model="groupId"
            :disabled="type == 0"
            placeholder="请输入工作室名称"
          >
            <el-option
              v-for="item in groupInfo"
              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员状态：">
          <el-select v-model="vipStatus" @change="choseVipStatus">
            <el-option
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄阶段：">
          <el-select v-model="ageType" @change="choseAgeType">
            <el-option
              v-for="item in ageGrades"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="诊断名称：">
          <el-select
            v-model="diseaseIds"
            @change="choseDiseaseIds"
            filterable
            placeholder="请选择诊断名称"
            multiple
          >
            <el-option
              v-for="item in diagnose"
              :key="item.diseaseId"
              :label="item.diseaseName"
              :value="item.diseaseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 随访日期 -->
        <el-form-item>
          <span slot="label">
            <span>
              <el-dropdown @command="chooseHospitailTimeType" size="medium ">
                <span class="el-dropdown-link">
                  {{ DateType
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in timeType"
                    :key="item.id"
                    :command="item.id"
                    >{{ item.type }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <el-date-picker
              v-model="followUpTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeFollowUpTime"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </span>
        </el-form-item>
        <!-- 手术信息 -->
        <el-form-item label="手术信息：">
          <el-cascader
            size="mini"
            :props="{
              expandTrigger: 'hover',
              multiple: 'true',
            }"
            placeholder="请选择手术信息"
            clearable
            collapse-tags
            @change="changeOperationBaseModeIds"
            v-model="operationBaseModeIds"
            :options="options"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="血压异常最高等级：">
          <el-select
            v-model="errorLevel"
            @change="changeErrorLevel"
            placeholder="请选择血压异常等级"
          >
            <el-option
              v-for="item in bloodPressure"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="低密度脂蛋白：">
          <el-select v-model="proteinLevel" @change="changeProteinLevel">
            <el-option
              v-for="item in lipoprotein"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="药品名称：">
          <el-input
            v-model="searchFrom.drugName"
            placeholder="请输入药品名称"
            suffix-icon="el-icon-search"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btngroup">
        <el-button type="primary" @click="onSubmit" class="btn1"
          >查询</el-button
        >
        <el-button @click="resetForm()">重置</el-button>
      </div>
    </div>
    <div class="btngroup2">
      <el-button type="primary" @click="downLoadSheet(searchFrom)" class="btn1"
        >导出</el-button
      >
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="650"
        :header-cell-style="{ background: '#F6F8FA', height: '45px' }"
        highlight-current-row
      >
        <el-table-column
          fixed
          type="index"
          label="序号"
          width="50"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed prop="name" label="姓名" align="center">
        </el-table-column>

        <el-table-column prop="gender" fixed label="性别" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.gender == 1">男</span>
            <span v-else-if="scope.row.gender == 2">女</span>
            <span v-else>——</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="diseaseName"
          label="诊断名称"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="hover">
              <div>
                <span v-for="(item, i) in scope.row.diseaseName" :key="i">
                  {{ item.diseaseName }}
                </span>
              </div>
              <span slot="reference">
                <span slot="reference">
                  <span v-if="Array.isArray(scope.row.diseaseName)">{{
                    scope.row.diseaseName[0].diseaseName
                  }}</span>
                  <span v-else>{{ scope.row.diseaseName }}</span>
                </span>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="groupName"
          label="工作室名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="managerDay"
          label="会员管理时长（天）"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="operationTime" label="手术日期" align="center">
        </el-table-column>
        <el-table-column
          prop="postoperativeTime"
          label="术后时间（天）"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="bmi" label="体重指数" width="150" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.bmi == '——'
                ? scope.row.bmi
                : (scope.row.bmi / 10).toFixed(1)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="errorNum"
          label="血压异常次数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="errorLevel"
          label="血压异常最高等级"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.errorLevel == 1">一级</span>
            <span v-else-if="scope.row.errorLevel == 2">二级</span>
            <span v-else-if="scope.row.errorLevel == 3">三级</span>
            <span v-else-if="scope.row.errorLevel == 4">四级</span>
            <span v-else>——</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sugarErrorNum"
          label="血糖异常次数"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="ldl" label="低密度脂蛋白" align="center">
        </el-table-column>
        <el-table-column
          prop="drug_info"
          label="当前用药"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover
              placement="left"
              width="500"
              trigger="hover"
              v-if="scope.row.drug_info"
            >
              <el-table :data="scope.row.drug_info">
                <el-table-column property="drug_name" label="名称">
                  <template slot-scope="scope">
                    {{ scope.row.drug_name ? scope.row.drug_name : '——' }}
                  </template>
                </el-table-column>
                <el-table-column property="drug_spec" label="规格">
                  <template slot-scope="scope">
                    {{ scope.row.drug_spec ? scope.row.drug_spec : '——' }}
                  </template></el-table-column
                >
                <el-table-column property="drug_mode" label="方式">
                  <template slot-scope="scope">
                    {{ scope.row.drug_mode ? scope.row.drug_mode : '——' }}
                  </template></el-table-column
                >
                <el-table-column
                  width="100"
                  label="用量"
                  property="drug_amount"
                >
                  <template slot-scope="scope">
                    {{ scope.row.drug_amount ? scope.row.drug_amount : '——' }}
                  </template>
                </el-table-column>
                <el-table-column width="100" label="用法" property="drug_usage">
                  <template slot-scope="scope">
                    {{ scope.row.drug_usage ? scope.row.drug_usage : '——' }}
                  </template>
                </el-table-column>
              </el-table>
              <span slot="reference">{{
                scope.row.drug_info[0].drug_name
              }}</span>
            </el-popover>
            <span v-else>——</span>
          </template>
        </el-table-column>
        <el-table-column prop="deathStatus" label="死亡状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.deathStatus == 0">是</span>
            <span v-if="scope.row.deathStatus == 1">否</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="echart-box">
      <div class="content-left">
        <div class="left-top">
          <div class="pie">
            <PieEchart :chartData="gaugeChartData"></PieEchart>
          </div>
          <div class="gauge">
            <GaugeEchart :chartData="genderRate"></GaugeEchart>
          </div>
        </div>
        <div class="left-bottom">
          <BarCodeEchart :chartData="chiefDisease"></BarCodeEchart>
        </div>
      </div>
      <div class="content-right">
        <div class="bar-echart">
          <BarEchart :chartData="complication"></BarEchart>
        </div>
        <div class="line-top-echart">
          <LineEchart
            :chartData="bloodPressureRate"
            :echartName="echartsName"
          ></LineEchart>
        </div>
        <div class="line-bottom-echart">
          <LineEchart
            :chartData="heartRate"
            :echartName="echartName"
          ></LineEchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarCodeEchart from './base-echart/bar-code-echart.vue';
import BarEchart from './base-echart/bar-echart.vue';
import PieEchart from './base-echart/pie-echart.vue';
import GaugeEchart from './base-echart/gauge-echart.vue';
import LineEchart from './base-echart/line-echart.vue';
import { httpReq } from '@/http/req';
export default {
  name: 'FollowData',
  components: { BarCodeEchart, LineEchart, GaugeEchart, BarEchart, PieEchart },
  data() {
    return {
      pageSearchFrom: {}, //选择页码和选择页限制时的searchFrom
      gaugeChartData: [], // 年龄段
      bloodPressureRate: [], // 血压
      chiefDisease: [], // 主要病症类型分布
      genderRate: [], // 性别分布
      heartRate: [], // 心率达标率
      complication: [], // 单患者病症数量分布
      echartName: '心率',
      echartsName: '血压',
      lineChartData: [
        ['1月', 23],
        ['2月', 122],
        ['3月', 414],
        ['4月', 12],
        ['5月', 51],
        ['6月', 125],
      ],
      DateType: '随访日期', // 随访日期
      vipStatus: '全部', //  会员状态：
      groupId: '全部', //  工作室名称
      ageType: '全部', //  年龄阶段：
      diseaseIds: [], // 诊断名称
      errorLevel: '全部', //  血压异常最高等级：
      proteinLevel: '全部', //  低密度脂蛋白：
      type: JSON.parse(window.sessionStorage.getItem('userInfo')).type,

      searchFrom: {
        // 搜索内容
        userContent: '', // 患者信息
        vipStatus: '', //  会员状态：
        groupId: '', //  工作室名称
        ageType: '', //  年龄阶段：
        // diseaseIds: '', // 诊断名称
        DateType: 1,
        startTime: '', //随访时间选择的开始内容
        endTime: '', //随访时间选择的结束内容
        drugName: '', //  药品名称
        proteinLevel: '', //  低密度脂蛋白：
        errorLevel: '', //  血压异常最高等级：
        operationBaseModeIds: '', //手术信息内容
      },
      operationBaseModeIds: '',
      //工作室名称内容
      groupInfo: [],
      //会员状态内容
      states: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 1,
          label: '正常',
        },
        {
          value: 0,
          label: '已过期',
        },
        {
          value: 11,
          label: '即将过期',
        },
      ],
      //年龄阶段内容
      ageGrades: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 1,
          label: '30以下',
        },
        {
          value: 2,
          label: '30-39',
        },
        {
          value: 3,
          label: '40-49',
        },
        {
          value: 4,
          label: '50-59',
        },
        {
          value: 5,
          label: '60-69',
        },
        {
          value: 6,
          label: '70-79',
        },
        {
          value: 7,
          label: '80以上',
        },
      ],
      //诊断病症信息
      diagnose: [],
      //随访日期类型
      timeType: [
        { type: '随访日期', id: 1 },
        { type: '复查日期', id: 2 },
        { type: '手术日期', id: 3 },
        { type: '出院日期', id: 4 },
        { type: '血压血糖异常时间', id: 5 },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      drugInfo: [],
      tableData: [],
      gridData: [],
      value1: '',
      //lipoprotein脂蛋白等级
      lipoprotein: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 1,
          label: '正常',
        },
        {
          value: 2,
          label: '偏高',
        },
        {
          value: 0,
          label: '偏低',
        },
      ],
      //血压等级
      bloodPressure: [
        {
          value: null,
          label: '全部',
        },
        {
          value: 1,
          label: '一级',
        },
        {
          value: 2,
          label: '二级',
        },
        {
          value: 3,
          label: '三级',
        },
        {
          value: 4,
          label: '四级',
        },
      ],

      //手术信息的多选
      options: [],
      followUpTime: [], //选择的时间
    };
  },
  created() {},
  mounted() {
    this.assistantHospital();
    this.operation();
    this.getDiagnose();
  },
  methods: {
    chooseHospitailTimeType(type) {
      this.timeType.forEach(item => {
        if (type == item.id) {
          this.DateType = item.type;
          this.searchFrom.DateType = item.id;
        }
      });
    },
    /**
     * 会员状态的选择
     * */
    choseVipStatus(type) {
      this.states.forEach(item => {
        if (type == item.value) {
          this.searchFrom.vipStatus = item.value;
          this.vipStatus = item.label;
        }
      });
    },
    /**
     * 年龄阶段的选择
     * */
    choseAgeType(type) {
      this.ageGrades.forEach(item => {
        if (type == item.value) {
          this.searchFrom.ageType = item.value;
          this.ageType = item.label;
        }
      });
    },
    /**
     * 手术信息的选择
     * */
    changeOperationBaseModeIds(type) {
      var list = [];
      type.forEach(item => {
        // this.searchFrom.operationBaseModeIds.push();
        list.push(item.slice(-1)[0]);
      });
      this.searchFrom.operationBaseModeIds = list.toString();
    },
    /**
     * 诊断名称的选择
     * */
    choseDiseaseIds(type) {
      console.log(type, 'type');
      type.forEach((item, index) => {
        if (item == '' && type.length > 1) {
          type.splice(index, 1);
        }
      });
      if (type.length !== 0) {
        this.searchFrom.diseaseIds = [];
        this.searchFrom.diseaseIds = type.toString();
      }
      if (type.length == 0) {
        delete this.searchFrom.diseaseIds;
        if (this.searchFrom) {
          const keys = Object.keys(this.searchFrom);
          //清除search中的null和'''
          for (var key of keys) {
            const val = this.searchFrom[key];
            if (
              typeof val === 'undefined' ||
              ((typeof val === 'object' || typeof val === 'string') && !val)
            ) {
              delete this.searchFrom[key];
            }
          }
        }
      }
    },
    /**
     * 血压异常的选择
     * */
    changeErrorLevel(type) {
      this.bloodPressure.forEach(item => {
        if (type == item.value) {
          this.searchFrom.errorLevel = item.value;
          this.errorLevel = item.label;
        }
      });
    },
    /**
     * 低密度脂蛋白的选择
     * */
    changeProteinLevel(type) {
      this.lipoprotein.forEach(item => {
        if (type == item.value) {
          this.searchFrom.proteinLevel = item.value;
          this.proteinLevel = item.label;
        }
      });
    },

    //页码改变
    handleCurrentChange(val) {
      this.currentPage = val;
      var searchFrom = this.pageSearchFrom;
      this.getPatients(this.currentPage, this.pageSize, searchFrom);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      var searchFrom = this.pageSearchFrom;
      this.getPatients(this.currentPage, this.pageSize, searchFrom);
    },
    /**
     * 本次随访时间选择事件
     * */
    changeFollowUpTime() {
      this.searchFrom.startTime = this.followUpTime[0];
      this.searchFrom.endTime = this.followUpTime[1];
    },
    /**
     * 重置
     * */
    resetForm() {
      this.searchFrom = {
        userContent: '', // 患者信息
        vipStatus: null, //  会员状态：
        groupId:
          JSON.parse(window.sessionStorage.getItem('userInfo')).type == 0
            ? JSON.parse(window.sessionStorage.getItem('userInfo')).groupId
            : null, //  工作室名称

        ageType: null, //  年龄阶段：
        diseaseIds: null, // 诊断名称
        DateType: 0,
        startTime: null, //随访时间选择的开始内容
        endTime: null, //随访时间选择的结束内容
        drugName: '', //  药品名称
        proteinLevel: null, //  低密度脂蛋白：
        errorLevel: null, //  血压异常最高等级：
        operationBaseModeIds: null, //手术信息内容
      };
      this.followUpTime = [];
      this.DateType = '随访日期';
      this.vipStatus = '全部';
      this.groupId =
        JSON.parse(window.sessionStorage.getItem('userInfo')).type == 0
          ? JSON.parse(window.sessionStorage.getItem('userInfo')).groupId
          : null;
      this.ageType = '全部';
      this.errorLevel = '全部';
      this.proteinLevel = '全部';
      this.diseaseIds = [];
      this.currentPage = 1;
      this.gaugeChartData = [];
      this.bloodPressureRate = [];
      this.chiefDisease = [];
      this.pageSize = 10;
      this.genderRate = [];
      this.complication = [];
      this.heartRate = [];
      this.operationBaseModeIds = [];
      this.$nextTick(() => {
        this.getPatients(1, 10, this.searchFrom);
        this.getEchart(this.searchFrom);
      });
    },
    /**
     * 搜索
     */
    onSubmit() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getPatients(1, 10, this.searchFrom);
      this.getEchart(this.searchFrom);
      this.gaugeChartData = [];
      this.bloodPressureRate = [];
      this.chiefDisease = [];
      this.genderRate = [];
      this.complication = [];
      this.heartRate = [];
    },
    /**
     * 获取默认患者列表
     */
    async getPatients(page, pageSize = 10, searchFrom = {}) {
      httpReq({
        url: '/followData/getFollowData',
        method: 'get',
        params: {
          pageSize,
          page,
          ...searchFrom,
        },
      }).then(res => {
        this.tableData = res.data.data;
        this.pageSearchFrom = this.searchFrom;
        this.total = res.data.records;
        this.tableData.forEach(item => {
          item.diseaseName = JSON.parse(item.diseaseName);
          var drugInfo = JSON.parse(item.drug_info);
          // eslint-disable-next-line camelcase
          item.drug_info = drugInfo;
          if (item) {
            const keys = Object.keys(item);
            for (var key of keys) {
              const val = item[key];
              if (
                typeof val === 'undefined' ||
                ((typeof val === 'object' || typeof val === 'string') && !val)
              ) {
                item[key] = key == 'drug_info' ? '' : '——';
              }
            }
          }
        });
      });
    },
    /**
     * 获取echart图标信息
     * */
    getEchart(searchFrom) {
      var item = searchFrom;
      if (item) {
        const keys = Object.keys(item);
        //清除search中的null和'''
        for (var key of keys) {
          const val = item[key];
          if (
            typeof val === 'undefined' ||
            ((typeof val === 'object' || typeof val === 'string') && !val)
          ) {
            delete item[key];
          }
        }
      }
      httpReq({
        url: 'chart/getChartsList',
        params: { ...item },
        method: 'get',
      }).then(res => {
        this.gaugeChartData = res.data.ageRate;
        this.chiefDisease = res.data.chiefDisease;
        this.genderRate = res.data.genderRate;
        this.complication = res.data.complication;
        var userId = JSON.stringify(res.data.userIdList);
        // eslint-disable-next-line no-eq-null
        if (searchFrom.groupId == null) {
          this.getDeviceRate(userId);
          this.getHeartRate(userId);
        } else {
          this.getDeviceRate(userId, searchFrom.groupId);
          this.getHeartRate(userId, searchFrom.groupId);
        }
      });
    },
    /**
     * 获取血压的图表信息
     * */
    getDeviceRate(userId, groupId) {
      httpReq({
        url: 'chart/getDeviceRate',
        data: { userId, groupId },
        method: 'post',
      }).then(res => {
        this.bloodPressureRate = res.data.bloodPressureRate;
      });
    },
    /**
     * 获取心率的图表信息
     * */
    getHeartRate(userId, groupId) {
      httpReq({
        url: 'chart/getHeartRate',
        data: { userId, groupId },
        method: 'post',
      }).then(res => {
        this.heartRate = res.data.heartRate;
      });
    },

    /**
     * 获取所有的病种
     * */

    async getDiagnose() {
      httpReq({
        url: '/followData/getDisease',
        method: 'get',
      }).then(res => {
        this.diagnose = res.data;
        this.diagnose.unshift({
          diseaseId: '',
          diseaseName: '全部',
        });
      });
    },
    /**
     * 获取所有的工作室
     * */

    async assistantHospital() {
      httpReq({
        url: '/followData/getAllGroup',
        method: 'get',
      }).then(res => {
        var userInfo = window.sessionStorage.getItem('userInfo');
        userInfo = JSON.parse(userInfo);
        if (userInfo.type == 0) {
          this.groupId = res.data[0].groupName;
          this.searchFrom.groupId = res.data[0].groupId;
          this.$nextTick(() => {
            this.getPatients(1, 10, this.searchFrom);
            this.getEchart(this.searchFrom);
            console.log('这个是type为工作室医生的请求');
          });
        } else {
          this.groupInfo = res.data;
          this.groupInfo.unshift({
            groupId: '',
            groupName: '全部',
          });

          this.searchFrom.groupId = null;
          this.groupId = '全部';
          this.$nextTick(() => {
            this.getPatients(1, 10, this.searchFrom);

            this.getEchart(this.searchFrom);
          });
        }
      });
    },
    /**
     * 工作室名称的选择choseGroupId
     * */
    choseGroupId(type) {
      this.groupInfo.forEach(item => {
        if (type == item.groupId) {
          this.searchFrom.groupId = item.groupId;
          this.groupId = item.groupName;
        }
      });
    },
    /**
     * 获取所有手术基础处理方式
     * */
    async operation() {
      httpReq({
        url: '/followData/getOperations',
        method: 'get',
      }).then(res => {
        this.options = this.generateTree2(res.data)[0].lv1;
      });
    },
    /*
     *数组转树*/
    generateTree2(arr) {
      var root = {};
      if (arr.length > 0) {
        var objMap = {};
        root['lv1'] = [];
        arr.forEach(function (item) {
          var node = {
            label: item.operation_name,
            value: item.operation_base_mode_id,
          };
          objMap[item.operation_base_mode_id] = node;
          if (item.operation_base_mode_pid === 0) {
            root['lv1'].push(node);
          } else {
            var parent = objMap[item.operation_base_mode_pid];
            // parent["expanded"] = true;
            if (parent['children']) {
              parent['children'].push(node);
            } else {
              parent['children'] = [];
              parent['children'].push(node);
            }
          }
        });
      }
      return [root];
    },
    /*
     *导出
     */
    downLoadSheet(info) {
      const params = {
        ...info,
        type: this.type,
        id: JSON.parse(window.sessionStorage.getItem('userInfo')).doctorId,
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // httpReq({
      //   url: '/followData/downloadFollowData',
      //   method: 'get',
      //   params: params,
      // }).then(res => {
      // });
      // return
      var dataArr = [];
      for (let i in params) {
        dataArr.push(`${i}=${params[i]}`);
      }

      // let link = document.createElement('a');
      let url = `${
        process.env.VUE_APP_baseUrl
      }followData/downloadFollowData?${dataArr.join('&')}`;
      this.dowloadFiles(url);
    },
    //当前页面下载
    dowloadFiles(url) {
      const link = document.createElement('a');

      link.setAttribute('download', 'xxx.xlsx');

      link.setAttribute('href', url);

      link.style.display = 'none';

      document.body.appendChild(link);

      link.click();

      link.remove();
    },
  },
};
</script>

<style scoped lang="less">
// .page-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
.seach-box {
  background: #fff;
  // margin: 0 14px;
  padding: 20px 0 20px 20px;
  border-radius: 5px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  // margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/.el-form {
    flex: 1;
    .el-input__inner {
      border-radius: 2px;
    }
    .minclass .el-form-item__content {
      width: 120px;
      margin-right: 10px;
      .el-date-editor.el-input {
        width: 120px;
      }
    }
    .minclass2 {
      .el-input__inner {
        width: 160px;
      }
    }
  }
}

.demo-form-inline /deep/.el-icon-arrow-up:before {
  content: '';
}
.demo-form-inline /deep/.el-icon-arrow-up:before {
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo-form-inline /deep/.el-icon-arrow-down:before {
  content: '';
}
.demo-form-inline /deep/.el-icon-arrow-down:before {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btngroup {
  width: 250px;
  border-left: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  .el-button {
    border-radius: 2px;
    width: 76px;
    height: 31px;
  }
  .btn1 {
    background: #155bd4;
  }
}
.btngroup2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  .el-button {
    border-radius: 2px;
    width: 76px;
    height: 31px;
  }
  .btn1 {
    background: #155bd4;
    margin-right: 26px;
  }
}
.tablebox {
  background: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  // margin: 14px 14px;
  margin-top: 8px;
  padding: 16px 7px;
  max-height: 800px;
  // height: 365px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  .el-pagination {
    align-self: flex-end;
    margin-top: 10px;
  }
  /deep/.el-table__header {
    background-color: #2c89dc !important;
  }
  // 滚动条的宽度
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
  /deep/.el-table__fixed {
    height: 100% !important;
  }
}
.echart-box {
  box-sizing: border-box;
  height: 1438px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  .content-left {
    height: 1438px;
    width: 53%;
    display: flex;
    flex-direction: column;
    .left-top {
      width: 100%;
      height: 506px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .pie {
        width: 45.5%;
        height: 506px;
        background: #ffffff;
      }
      .gauge {
        width: 53%;
        height: 506px;
        background: #ffffff;
      }
    }
    .left-bottom {
      margin-top: 10px;
      height: 916px;
      background: #ffffff;
    }
  }
  .content-right {
    width: 46%;
    height: 1438px;
    display: flex;
    flex-direction: column;
    .bar-echart {
      height: 506px;
      margin-bottom: 10px;
      width: 100%;
      background: #ffffff;
    }
    .line-top-echart {
      height: 452px;
      margin-bottom: 12px;
      background: #ffffff;
    }
    .line-bottom-echart {
      height: 452px;
      background: #ffffff;
    }
  }
}
</style>
