<template>
  <div class="bar-code-chart" ref="bar_code_echart" />
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.bar_code_echart);
      this.setOptions(this.chartData);
    },
    /**
     * setOptions需要接参数的
     */
    setOptions(val) {
      var _that = this;
      if (val.length == 0) {
        this.chart.setOption({
          title: [
            {
              left: '15',
              top: '8',
              text: '主要病症类型分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
        });
      } else if (val.length !== 0) {
        const dateList = [];
        val[0].forEach(item => {
          dateList.push(item.disease_name);
        });
        const valueList = [];
        val[0].forEach(item => {
          valueList.push(item.numbers);
        });
        this.chart.setOption({
          xAxis: {
            type: 'value',
            position: 'top',
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: 'category',
            data: dateList,
            inverse: true,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              inside: false,
              margin: 15,
            },
          },
          title: [
            {
              left: '15',
              top: '8',
              text: '主要病症类型分布',
              textStyle: {
                color: '#323233',
                lineHeight: 20,
                fontSize: 16,
                fontWeight: '600',
                fontFamily: ' PingFangSC-Medium, PingFang SC',
              },
            },
          ],
          grid: {
            left: '6%',
            right: '6%',
            bottom: '3%',
            top: '18%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              const content = `<div class="tooltip-txt" style="color:#2c93fa;">${params[0].value}</div>`;
              return content;
            },
          },
          series: [
            {
              type: 'bar',
              data: valueList,
              realtimeSort: true,
              barMaxWidth: '20%',
              barMinWidth: '6%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: '#2B94F9' },
                  { offset: 0.5, color: '#2B94F9' },
                  { offset: 1, color: '#2A94F9' },
                ]),
              },
            },
          ],
        });
      }

      window.addEventListener('resize', () => {
        _that.chart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bar-code-chart {
  width: 100%;
  height: 100%;
}
.tooltip-txt {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 20px;
}
</style>
